import React from "react";

import type { SxProps } from "@mui/material";
import { Skeleton } from "@mui/material";

interface Props {
  height?: number | string;
  sx?: SxProps;
  testId?: string;
}

export const DefaultLoader = (props: Props) => {
  return (
    <Skeleton
      variant="rectangular"
      animation="wave"
      height={props.height ?? 280}
      data-test={props.testId ?? Math.random()}
      sx={{ ...props.sx }}
    />
  );
};
