import { CircularProgress, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const STYLES: any = {
  direction: "column",
  alignItems: "center",
  justifyContent: "center",
  spacing: 4,
  pt: 8,
};

export const SettingsLoader = () => {
  const { t } = useTranslation();
  const id = "my_gradient";

  return (
    <Stack {...STYLES}>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id={`${id}`} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="rgb(230, 30, 41)" />
            <stop offset="33%" stopColor="rgb(221, 116, 122)" />
            <stop offset="66%" stopColor="rgb(224, 178, 180)" />
            <stop offset="100%" stopColor="rgb(223, 220, 220)" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        size={64}
        sx={{ "svg circle": { stroke: `url(#${id})` } }}
      />
      <Typography fontStyle="italic" color={grey[500]}>
        {t("loadingSettings")}
      </Typography>
    </Stack>
  );
};
