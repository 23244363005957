import { useCallback } from "react";

import useSWR from "swr";

import type { AssetIdsAttributesRequestApi } from "@/apis/services/AssetService";

import { useServices } from "./useServices";

interface Parameters {
  cursor?: string;
  limit?: number;
}

export const generateAttributesKey = (
  attributes: string[],
  query?: Parameters
) => {
  return `useAllAssetsByAttributes/${query?.limit}/${
    query?.cursor
  }/${attributes.flat()}`;
};

export const useAllAssetsByAttributes = (
  attributes: string[],
  query?: Parameters,
  transform?: (a: any[]) => any[]
) => {
  const { asset } = useServices();

  const { data, error, mutate } = useSWR(
    attributes.length > 0 ? generateAttributesKey(attributes, query) : null,
    async () => {
      const assets = await asset.assets.searchAssets({ attributes }, query);
      return assets.data;
    }
  );

  const updateCache = useCallback(
    async (id: any, field: any, value: any) => {
      const results = data?.results ?? [];
      const index = results.findIndex((a: any) => a.id === id) ?? 0;
      const newData = {
        ...data,
        results: [...results],
      };
      const assetToUpdate = newData.results[index];
      newData.results[index] = { ...assetToUpdate, [field]: value };

      mutate(newData, {
        optimisticData: { ...newData },
        rollbackOnError: true,
        revalidate: false,
        populateCache: true,
      });
    },
    [mutate]
  );

  const _data = transform
    ? transform(data?.results || [])
    : data?.results || [];

  return {
    data: _data,
    cursor: data?.cursor || "",
    isLoading: !error && !data,
    isError: error,
    updateCache,
  };
};

export const useSearchAssetsByIds = (request: AssetIdsAttributesRequestApi) => {
  const { attributes = [], asset_ids } = request;
  const { asset } = useServices();

  const assetsKey = asset_ids.join();
  const attributesKey = (attributes ?? []).join();
  const key =
    assetsKey && attributesKey
      ? `useSearchAssetsByIds/${(attributes ?? []).join()}/${asset_ids.join()}`
      : null;

  const { data, isLoading, error } = useSWR(key, async () => {
    const assets = await asset.assets.searchAssetsByIds(request);
    return assets.data;
  });

  return {
    data,
    isLoading: isLoading,
    isError: error,
  };
};
